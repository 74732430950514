<script>
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

export default {
  props: ["currentQuestionSubjects", "currentProgram", "programsList", "path", "paramsId"],
  data() {
    return {
      current: {}
    }
  },
  watch: {
    currentQuestionSubjects(questionSubjects) {
      if (!isEmpty(questionSubjects)) {
        let program = find(this.programsList, (p) => {
          return p.id === questionSubjects[0].program_id;
        })

        if (program && questionSubjects.some((element) => element.program_id !== program.id)) {
          program = null;
        }

        let path = this.path || ["category", "program_id"]
        this.$parent.currentProgram = this.$parent.program = program
        this.$store.commit('SET_STATE', {path: path, value: program})
      }
    }
  },
  computed: {
    programs() {
      return this.programsList
    }
  },
  mounted() {
    if (this.paramsId) {
      let value = find(this.programsList, (p) => {
        return p.id == this.paramsId;
      })
      this.$parent.setProgram(value)
    }
  }
}
</script>
