<script>
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import filter from "lodash/filter";

export default {
  props: ["currentQuestionSubject", "currentProgram", "programsList", "path"],
  data() {
    return {
      current: {}
    }
  },
  watch: {
    currentQuestionSubject(n) {
      if (!isEmpty(n)) {
        let value = find(this.programsList, (p) => {
          return n.program_id == p.id;
        })
        let path = this.path || ["claim", "program_id"]
        this.$store.commit('SET_STATE', {path: path, value: value})
        this.$store.commit('SET_ERRORS', {path: ["claim", "program_id"], value: []})
      }
    }
  },
  computed: {
    programs() {
      let filteredProgramsList = this.programsList
      if (this.currentQuestionSubject) {
        filteredProgramsList = filter(filteredProgramsList, (p) => {
          return this.currentQuestionSubject.program_id === p.id;
        })
      }
      return filteredProgramsList
    }
  },
  mounted() {
    if (this.currentProgram) {
      this.$parent.setProgram(this.currentProgram)
    }
  }
}
</script>
