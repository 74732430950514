<script>
import MfcSelect from '../form/MfcSelect'
import claimDepartments from './ClaimDepartments'
import claimQuestions from './ClaimQuestions'
import claimPrograms from './ClaimPrograms'

export default {
  components: {
    departments: claimDepartments,
    questions: claimQuestions,
    programs: claimPrograms,
    mfcselect: MfcSelect
  },

  props: ["isCitizenRegistrationValid"],

  data() {
    return {
      currentDepartment: null,
      currentQuestionSubject: null,
      currentProgram: null
    }
  },

  methods: {
    setDepartment(department) {
      if (!this.isCitizenRegistrationValid && department.claims_only_from_region) {
        this.currentDepartment = null
      } else {
        this.currentDepartment = department;
      }
    },
    setQuestionSubject(questionSubject) {
      this.currentQuestionSubject = questionSubject;
    },
    setProgram(program) {
      this.currentProgram = program;
    }
  },

  mounted() {
    if (typeof this.isCitizenRegistrationValid !== 'undefined')
      this.$store.commit('SET_STATE', {
        path: ['citizenHasInvalidRegistration'],
        value: !this.isCitizenRegistrationValid
      });
  }
}
</script>
