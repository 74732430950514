<script>
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

export default {
  props: ["currentProgram", "currentQuestionSubjects", "questionSubjectsList", "path", "paramsId"],
  data() {
    return {
      current: {}
    }
  },
  computed: {
    questionSubjects() {
      let filteredQuestionSubjectsList = this.questionSubjectsList;
      if (this.$store.state.citizenHasInvalidRegistration) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return !q.claims_only_from_region;
        });
      }
      if (this.currentProgram) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return q.program_id === this.currentProgram.id
        });
      }
      return filteredQuestionSubjectsList;
    }
  },
  watch: {
    currentProgram(newProgram) {
      const questionSubjectIds = this.$store.state.category.question_subject_ids

      if (!isEmpty(newProgram) && questionSubjectIds) {
        if (questionSubjectIds.length > 0 && (questionSubjectIds[0].program_id !== newProgram.id)) {
          let path = this.path || ["category", "question_subject_ids", '[]']
          this.$parent.currentQuestionSubjects = this.$parent.questionSubjects = null;
          this.$store.commit('SET_STATE', {path: path, value: null})
        }
      }
    }
  },
  mounted() {
    if (this.paramsId) {
      let values = this.questionSubjectsList.filter((d) => this.paramsId.includes(d.id));
      this.$parent.setQuestionSubjects(values);
    }
  }
}
</script>
