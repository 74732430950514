<script>
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

export default {
  props: ["currentDepartment", "currentProgram", "questionSubject", "questionSubjectsList", "path", "paramsId"],
  data() {
    return {
      current: {}
    }
  },
  computed: {
    questionSubjects() {
      let filteredQuestionSubjectsList = this.questionSubjectsList;
      if (this.$store.state.citizenHasInvalidRegistration) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return !q.claims_only_from_region;
        });
      }
      if (this.currentDepartment) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return q.department_id == this.currentDepartment.id
        });
      }
      if (this.currentProgram) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return q.program_id == this.currentProgram.id
        });
      }
      return filteredQuestionSubjectsList;
    }
  },
  watch: {
    currentDepartment(newDepartment) {
      if (!isEmpty(newDepartment)) {
        if (this.$store.state.claim.question_subject_id && (this.$store.state.claim.question_subject_id.department_id != newDepartment.id)) {
          let path = this.path || ["claim", "question_subject_id"]
          this.$parent.questionSubject = this.$parent.currentQuestionSubject = null;
          this.$store.commit('SET_STATE', {path: path, value: null})
        }
      }
    },
    currentProgram(newProgram) {
      if (!isEmpty(newProgram)) {
        if (this.$store.state.claim.question_subject_id && (this.$store.state.claim.question_subject_id.program_id != newProgram.id)) {
          let path = this.path || ["claim", "question_subject_id"]
          this.$parent.questionSubject = this.$parent.currentQuestionSubject = null;
          this.$store.commit('SET_STATE', {path: path, value: null})
        }
      } else {
        if (this.questionSubject.program_id !== null) {
          let path = this.path || ["claim", "question_subject_id"]
          this.questionSubject = this.$parent.currentQuestionSubject = null;
          this.$store.commit('SET_STATE', {path: path, value: null})
        }
      }
    }
  }
}
</script>
