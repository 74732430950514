<script>
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import filter from "lodash/filter";

export default {
  props: ["currentQuestionSubject", "departmentsList", "paramsId", "path"],
  data() {
    return {
      current: {}
    }
  },
  watch: {
    currentQuestionSubject(n) {
      if (!isEmpty(n)) {
        let value = find(this.departmentsList, (d) => {
          return d.id == n.department_id;
        })
        let path = this.path || ["claim", "department_id"]
        this.$store.commit('SET_STATE', {path: path, value: value})
        this.$store.commit('SET_ERRORS', {path: ["claim", "department_id"], value: []})
      }
    }
  },
  computed: {
    departments() {
      let filteredDepartmentsList = this.departmentsList
      if (this.$store.state.citizenHasInvalidRegistration) {
        filteredDepartmentsList = filter(filteredDepartmentsList, (d) => {
          return !d.claims_only_from_region
        })
      }
      return filteredDepartmentsList
    }
  },
  mounted() {
    if (this.paramsId) {
      let value = find(this.departmentsList, (d) => {
        return d.id == this.paramsId;
      })
      this.$parent.setDepartment(value)
    }
  }
}
</script>
