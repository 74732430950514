import { render, staticRenderFns } from "./MfcAttachmentUpload.vue?vue&type=template&id=6e8d8280&scoped=true&"
import script from "./MfcAttachmentUpload.vue?vue&type=script&lang=js&"
export * from "./MfcAttachmentUpload.vue?vue&type=script&lang=js&"
import style0 from "./MfcAttachmentUpload.vue?vue&type=style&index=0&id=6e8d8280&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8d8280",
  null
  
)

export default component.exports