<template>
  <mfc-input-box :errors="cmpErrors">
    <multiselect v-model="cmpValue"
                 label="label"
                 track-by="title"
                 :options="cmpOptions"
                 :max-height="310"
                 :option-height="104"
                 :multiple="multiple"
                 :close-on-select="!multiple"
                 :custom-label="customLabel"
                 :show-labels="false"
                 :preserve-search="true"
                 :clear-on-select="false"
                 :class="{filled: cmpValue}"
                 :placeholder="inputPlaceholder"
                 @input="update(cmpValue)"
                 @open="cmpErrors = []"
                 :disabled="disabled"
                 :alphabet-order="alphabetOrder"
                 :allow-empty="canBeEmpty"
                 :searchable="searchable"
    >
      <template slot="singleLabel" slot-scope="props">
        <div class="singleLabel" :class="{filled: cmpValue}">
          <label for="">{{label}}</label>
          <div class="value" vb-ne="">{{cmpValue.title}}</div>
        </div>
      </template>
      <template v-slot:selection="{values, isOpen}">
        <div class="singleLabel" :class="{filled: cmpValue}" v-if="values.length">
          <label for="">{{label}}</label>
          <div class="value" vb-ne="">
            <span v-for="val in values">{{val.title}}</span>
          </div>
        </div>
      </template>
      <template slot="placeholder" slot-scope="props">
        <div class="singleLabel">
          <label>{{label}}</label>
        </div>
      </template>
      <template v-slot:option="{option}">
        <div class="option" v-if="multiple">
          <span class="mark" :class="{checked: isSelected(option)}" row="cn"></span>
          <span class="label">{{option['title']}}</span>
        </div>
      </template>
      <template v-slot:noResult>
        Элементы не найдены
      </template>
      <template v-slot:noOptions>
        Нет доступных элементов
      </template>
      <template v-slot:caret>
        <div class="multiselect__select">
          <i class="mfcicon-arrow-down"></i>
        </div>
      </template>
      <template v-slot:tag="{values, search, isOpen}">
        <div></div>
      </template>
    </multiselect>
    <select :name="name" v-show="false" v-if="cmpValue" :multiple="multiple">
      <option></option>
      <option v-for="option in cmpOptions"
              :value="option['value']"
              :key="option['value']"
              :selected="isSelected(option)">
        {{option['title']}}
      </option>
    </select>
  </mfc-input-box>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import inputMixin from '../../mixins/inputMixin'
  import MfcInputBox from './MfcInputBox'
  import filter from 'lodash/filter'
  import find from 'lodash/filter'
  import isEmpty from 'lodash/isEmpty'


  export default {
    mixins: [inputMixin],
    components: {
      Multiselect,
      MfcInputBox
    },
    props: {
      options: {
        type: [String, Array],
        default: []
      },
      multiple: {
        default: false
      },
      inputPlaceholder: {
        type: String,
        default: "Поиск..."
      },
      alphabetOrder: {
        type: Boolean,
        default: true
      },
      canBeEmpty: {
        type: Boolean,
        default: true
      },
      searchable: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        selectValue: undefined,
      }
    },
    computed: {
      cmpOptions: {
        get() {
          if (!this.alphabetOrder || isEmpty(this.options)) return this.options

          if (this.options[0].value === '') {
            let defaultOption = this.options[0]
            return [defaultOption].concat(this.options.slice(1).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase(), 'ru')))
          }

          return this.options.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase(), 'ru'))
        }
      },
    },
    methods: {
      customLabel ({ title, value }) {
        return `${title}`
      },
      isSelected(option) {
        if (!this.cmpValue) {
          return false
        }

        if (this.cmpValue instanceof Array) {
          return !!this.cmpValue.find(v => v["value"] === option["value"])
        }

        return this.cmpValue["value"] === option["value"]
      },

      update(e) {
        if (this.name == "settings[region]") {
          this.$store.commit('SET_STATE', {path: ['regionSelected'], value: (this.cmpValue) ? true : false});
        }
        this.$emit('input', this.cmpValue);
      },
    },
    created () {
      if (this.cmpValue instanceof Array) {
        this.cmpValue = this.cmpValue[0]
        this.cmpValue = filter(this.cmpOptions, (o) => {
          if (this.cmpValue) return this.cmpValue.find(v => v == o.value)
        });
      } else {
        let findValue = this.cmpOptions.find(v => v["value"] == this.cmpValue);
        if (findValue) this.cmpValue = findValue;
        else this.cmpValue = undefined
      }
    },
    mounted () {
      let input = this.$el.getElementsByClassName("multiselect__input")[0];
      if (input) {
        input.addEventListener('focus', () => this.cmpErrors = [])
      }
      if (this.name == "settings[region]") {
        this.$store.commit('SET_STATE', {path: ['regionSelected'], value: (this.cmpValue) ? true : false});
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  @import '../../assets/styles/variables';
  @import '../../assets/styles/mfcicons.autogen';
  @import '../../assets/styles/colours';

  .input-box {
    &.white {
      .multiselect__tags {
        background: rgb(255, 255, 255);
      }
      .singleLabel, .singleLabel.filled {
        background: rgb(255, 255, 255)!important;
      }
      &.v2 {
        .multiselect__tags {
          border: 1px solid rgb(218, 218, 218);
        }
        .multiselect__input {
          border: unset !important;
        }
        .multiselect--active {
          .singleLabel {
            display: none !important;
          }
          .singleLabel.filled {
            display: none !important;
          }
        }
      }
    }
    &.with-error {
      .multiselect__tags {
        border: 1px solid $error-color;
      }
    }
  }

  .option {
    position: relative;
    display: inline-block;
    min-height: 32px;
    line-height: 32px;
    padding-left: 32px + 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 1) !important;


    &:hover .mark {
      background-color: rgb(223, 222, 218);
      border: 1px solid rgb(223, 222, 218);
    }

    &:active .mark {
      background-color: rgb(195, 194, 190);
      border: 1px solid rgb(195, 194, 190);
    }

    &:hover .mark.checked {
      background-color: $primary-color-middle;
      border: 1px solid $primary-color-middle;
    }

  }

  .mark {
    position: absolute;
    top: 4px;
    left: 0;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(218, 218, 218);
    border-radius: 4px;
    height: 24px;
    width: 24px;
    font-size: 32px - 12px;

    &.checked {
      background-color: $primary-color-middle;
      border: 1px solid $primary-color-middle;

      &::after {
        display: flex;
      }
    }

    &::after {
      @extend .mfcicon-base-mixin;
      @extend .mfcicon-checked-mixin;
      color: rgb(255, 255, 255);
      position: absolute;
      display: none;
    }
  }

  .multiselect {

    &__select {
      position: absolute;
      z-index: $mselect-zindex;
      right: 21px;
      top: 25px;
      font-size: 14px;
      padding: 0;
      width: auto;
      height: auto;
      color: rgb(135, 135, 135);

      &::before {
        display: none;
      }
    }

    &__tags {
      height: 62px;
      max-height: 62px;
      overflow: hidden;
      background: rgb(245, 245, 245);
      border-radius: 6px;
      border: 1px solid rgb(245, 245, 245);
      padding: 0;
    }

    &__input {
      padding: 20px 16px 24px 16px;

      background: rgb(255, 255, 255);
      border-radius: 6px;
      border: none !important;
      font-size: 18px;
    }

    &--above .multiselect__content-wrapper {
      top: unset;
    }

    &__content-wrapper {
      background: rgb(255, 255, 255);
      border-radius: 0px 0px 6px 6px;
      border: 1px solid rgb(218, 218, 218);
      box-shadow: none !important;
      top: calc(100% - 1px);

      ul {
        max-width: 100%;
        span {
          overflow-x: hidden;
          white-space: normal;
          text-overflow: ellipsis;
        }
      }
    }

    &__element {
      color: rgb(0, 0, 0);
      font-size: 18px;
      font-weight: normal;
    }

    &__option {
      padding: 13px 15px;
      background: white;
      border-radius: 0px;
      display: block;
      min-height: 40px;
      line-height: unset;
      text-decoration: none;
      text-transform: none;
      vertical-align: middle;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background: rgb(245, 245, 245);
      }
      .option {
        // переопределение стилей multiselect
        padding-left: 35px !important;
      }
    }

    &__placeholder {
      margin: 0;
      padding: 0;
    }

    &__option--selected, &__option--highlight {
      color: rgb(0, 0, 0) !important;
      background: rgb(245, 245, 245) !important;
      font-weight: normal;
    }

    &.multiselect--active {
      // .multiselect__input {
      //   border: 1px solid rgb(218, 218, 218) !important;
      // }

      .multiselect__tags {
        border: 1px solid rgb(218, 218, 218) !important;
      }
      .multiselect__select {
        color: #000;
      }
    }


    &:not(.multiselect--active) {
      .multiselect__input {
        border: none !important;
      }
    }

    &__single {
      margin: 0;
      padding: 0;
      line-height: unset;
    }

    &--disabled {
      opacity: unset;
      border-radius: 8px;
      .multiselect__select {
        background: #f5f4f2;
      }
      .singleLabel.filled {
        background: #f5f4f2 !important;
        label {
          color: #9e9b98 !important;
        }
        .value {
          color: #75736f !important;
        }
      }
    }

    label {
      overflow-y: hidden;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 24px);
    }

    .singleLabel {
      position: relative;
      padding: 19px 36px 24px 16px;


      label {
        color: rgb(135, 135, 135);
        font-size: 18px;
        font-weight: normal;
      }

      .value span:not(:last-child):after {
        content: ", ";
      }

      &.filled {
        background: rgb(245, 245, 245);
        border-radius: 6px;
        padding: 10px 36px 12px 16px;
        label {
          font-size: 14px;
          top: 11px;
        }
        .value {
          color: rgb(0, 0, 0);
          font-size: 18px;
          font-weight: normal;
          overflow: hidden;
          margin-top: 5px;
        }
      }
    }

  }

  .input-box.v3 {
    .multiselect {
      min-height: 32px;
      &__select {
        right: 14px;
        top: 18px;
      }
      &__tags {
        min-height: 50px;
        max-height: 50px;
        border: 1px solid rgba(26, 26, 26, 0.1);
        border-radius: 4px;

        .multiselect__input {
          padding: 15px 16px;
          margin: 0;
          font-size: 13px;
          line-height: 18px;

          &::placeholder {
            font-size: 13px;
            line-height: 18px;
            color: #A6A6A6;
          }
        }

        .multiselect__single {
          .singleLabel {
            font-size: 13px;
            line-height: 18px;

            &.filled {
              padding: 6px 16px 6px 16px;

              label {
                font-size: 10px;
                line-height: 17px;
                top: 3px;
              }

              .value {
                margin: 0;
                font-size: 13px;
                line-height: 18px;
              }
            }
          }
        }
        .singleLabel.filled {
          padding: 0 16px;

          label {
            font-size: 10px;
            line-height: 14px;
            top: 0px;
          }
          .value {
            margin: 0;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      &__placeholder {
        .singleLabel {
          padding: 15px 16px;
          label {
            font-size: 13px;
            line-height: 18px;
          }
        } 
      }

      &__content-wrapper {
        border-radius: 0px 0px 4px 4px;

        .multiselect__content {
          .multiselect__element {
            font-size: 13px;
            line-height: 18px;

            .multiselect__option {
              .option {
                min-height: auto;
                line-height: 18px;
                .label {
                  font-size: 13px;
                  line-height: 18px;
                }

                .mark {
                  top: 2px;
                  height: 16px;
                  width: 16px;
                  font-size: 11px;
                }
              }

              &--selected {
                background: white !important;
                color: #e34226 !important;
                .option > .label {
                  color: #e34226;
                }
              }

              &--highlight {
                background: rgb(245, 245, 245) !important;
              }
            }
          }
        }
      }

      &--active {
        .singleLabel {
          display: none;
        }
      }
    }
  }

  .input-box.v3:not(.white) {
      .multiselect {
        .multiselect__select {
          top: 11px;
        }
        .multiselect__tags {
          min-height: 34px;
          max-height: 34px;
          border: 1px solid transparent !important;

          .multiselect__input {
            padding: 7px 12px;
          }

          .singleLabel.filled {
            padding: 8px 30px 8px 12px;
            label {
              display: none;
            }
          }
        }

        .multiselect__placeholder {
          .singleLabel {
            padding: 7px 12px;
            label {
              font-size: 13px;
              line-height: 18px;
            }
          } 
        }
        .multiselect__content-wrapper {
          border: none;
          box-shadow: 0px 8px 6px rgba(131, 131, 131, 0.16) !important;
        }

        &--active {
          .multiselect__tags {
            border: 1px solid transparent !important;
            box-shadow: 0px 8px 6px rgba(131, 131, 131, 0.16) !important;
          }
        }
      }
    }
</style>
