<script>
import scheduleDepartments from "./ScheduleDepartments.vue";
import scheduleQuestions from "./ScheduleQuestions.vue";
import MfcSelect from "../form/MfcSelect.vue";

export default {
  components: {
    departments : scheduleDepartments,
    questions: scheduleQuestions,
    mfcselect: MfcSelect
  },
  data() {
    return {
      currentDepartment: null,
      currentQuestionSubject: null,
    }
  },
  methods: {
    setDepartment(department) {
      this.currentDepartment = department;
    },
    setQuestionSubject(questionSubject) {
      this.currentQuestionSubject = questionSubject;
    }
  }
}
</script>
