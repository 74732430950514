<template>
  <div class="form-color-picker">
    <input :name="`${modelName}[color]`" type="hidden" v-model="current_color">
    <div class="title" > {{ title }} </div>
    <div class="description" > {{ description }} </div>
    <div class="colors" row="">
      <div :class="{color: true, current: color === current_color}" v-for="color in colors" @click="current_color = color" row="cn cn">
        <div class="square" :style="{background: color}"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ColorPicker',
    props: {
      modelName: String,
      color_prop: String,
      title: String,
      description: String,
      colors: {
        type: Array,
        default() {
          return [
            "rgb(255, 151, 151)",
            "rgb(255, 191, 192)",
            "rgb(245, 237, 230)",
            "rgb(254, 217, 170)",
            "rgb(255, 235, 178)",
            "rgb(255, 220, 137)",
            "rgb(255, 229, 0)",
            "rgb(179, 240, 138)",
            "rgb(201, 255, 191)",
            "rgb(187, 226, 200)",
            "rgb(154, 182, 248)",
            "rgb(175, 215, 255)",
            "rgb(191, 241, 255)",
            "rgb(186, 207, 233)",
            "rgb(217, 191, 255)",
          ];
        }
      },
    },
    computed: {
      current_color: {
        get() {
          return this.$store.state[this.modelName].color
        },
        set(v) {
          this.$store.commit('SET_STATE', {path: [this.modelName, 'color'], value: v})
        }
      }
    },
    created() {
      this.current_color = this.color_prop || this.colors[0]
    }
  }
</script>

<style lang='scss' scoped>
  .form-color-picker {
    padding: 17px 22px 23px;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    border: 1px solid rgb(218, 218, 218);
    margin-left: 18px;
    width: 100%;
    .title {
      margin-bottom: 2px;
    }
    .description {
      color: rgb(135, 135, 135);
      font-size: 14px;
      margin-bottom: 16px;
    }
    .colors {
      .color {
        height: 32px;
        width: 32px;
        border-radius: 6px;
        border: 2px solid rgb(218, 218, 218);
        cursor: pointer;

        &.current {
          border: 2px solid rgb(237, 83, 56);
        }

        .square {
          border-radius: 3px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
</style>
