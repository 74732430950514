import ClickOutside from 'vue-click-outside'
import toastr from 'toastr'

export default {
  props: {
    activeReception: {
      type: Boolean,
    },
    claimId: {
    },
    state: {
      type: String
    }
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      showStates: false,
      modalConfirm: false,
      stateName: null,
      enableStates: this.activeReception,
    }
  },
  methods: {
    hide () {
      this.showStates = false
    },
    open () {
      if (this.enableStates) {
        this.showStates = !this.showStates
      }
    },
    showModal (link, state, currentAdmin) {
      this.stateName = state;
      if (state == "refuze") {
        this.$store.commit('SET_STATE', {path: ['refuzePartialShow'], value: true})
      }
      else if (currentAdmin && ["consider","consult","clarify"].includes(state)) {
        this.$store.commit('SET_STATE', {path: ['performerPartialShow'], value: state})
      }
      else if (!currentAdmin && state == 'to_additional' && link.includes('negotiation=1')) {
        this.$store.commit('SET_STATE', { path: ['startAdditionalConsultation'], value: true })
        this.$axios.get(link)
          .then((response) => {
            if (!response.data.success) {
              toastr.error(response.data.message)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.goToState(link)
      }
    },
    goToState (link) {
      Turbolinks.visit(link)
    },
    async checkReceptionStart(claim_id, cb) {
      try {
        const {data} = await this.$axios.get(this.$routes.reception_start_official_claim_path({id: claim_id}))
        this.enableStates = data.reception_start
        if (cb) cb() 
      } catch (e) {
        console.error("ERROR", e)
      } 
    },
  },
  created () {
    if (!this.enableStates && this.state == "consultation") {
      this.checkReceptionStart(this.claimId)
      this.interval = setInterval(() => {
        this.checkReceptionStart(this.claimId, () => {
          if (this.enableStates) {
            clearInterval(this.interval)
          }
        })
      }, 5000);
    } else {
      this.enableStates = true;
    }
  },
  destroyed () {
    if (this.interval) clearInterval(this.interval)
  }
}
