<script>
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

export default {
  props: ["currentDepartment", "questionSubject", "questionSubjectsList", "path"],
  data() {
    return {
      current: {}
    }
  },
  computed: {
    questionSubjects() {
      let filteredQuestionSubjectsList = this.questionSubjectsList;
      if (this.currentDepartment) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return q.department_id === this.currentDepartment.id || q.id === 'all'
        });
      }
      return filteredQuestionSubjectsList;
    }
  },
  methods: {
    updateQuestionSubject(allQuestionSubject) {
      const path = this.path;
      this.$parent.questionSubject = this.$parent.currentQuestionSubject = allQuestionSubject;
      this.$store.commit('SET_STATE', { path, value: allQuestionSubject });
    }
  },
  watch: {
    currentDepartment(newDepartment) {
      const allQuestionSubject = find(this.questionSubjectsList, (q) => q.id === 'all');
      const scheduleSubjectId = this.$store.state.schedule.question_subject_id;

      if (!isEmpty(newDepartment) && scheduleSubjectId !== null) {
        const departmentChanged = scheduleSubjectId.department_id !== newDepartment.id;
        if (departmentChanged) {
          this.updateQuestionSubject(allQuestionSubject);
        }
      } else if (scheduleSubjectId === null) {
        this.updateQuestionSubject(allQuestionSubject);
      }
    }
  }
}
</script>
