<template>
<div class="chat claim__chat">
  <div class="chat__header">
    <span class="chat__title">Дополнительная консультация</span>
  </div>

  <div class="chat__main hide-scrollbar">
    <div class="chat__empty" v-if="isChatEmpty">
      <div class="chat__empty-image"></div>
      <div class="chat__empty-description">
        Чат дополнительной консультации пока пуст. Отправьте сообщение для начала диалога.
      </div>
    </div>

    <ul class="chat__messages hide-scrollbar" v-else>
      <li class="chat__message message" :class="{ 'message--from-me': message.from == userRole }" v-for="message in messages" :key="message.id">
        <span class="message__author">{{ author(message.from) }}</span>
        <div class="message__bubble">
          <div class="message__bubble-text" v-if="message.text">
            {{ message.text}}
          </div>
          <ul class="message__bubble-documents" v-if="message.documents">
            <li v-for="doc in message.documents">
              <a class="message__bubble-document" download target="_blank" :href="$routes.attachment_path(doc.id)">
                <div class="message__bubble-document-icon">
                  <i class="mfcicon-clip" />
                </div>
                <div class="message__bubble-document-text">
                  {{ doc.description | fileName }}
                </div>
              </a>
            </li>
          </ul>
          <div class="message__bubble-send-date">
            {{ message.date | formatDate }}
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="chat__actions" v-if="isAdditionalConsultation && canDoChatActions">
    <div class="chat__attach-file" @click="$event => $refs.file.click($event)">
        <i class="mfcicon-clip" />
    </div>
    <input type="file"
         ref="file"
         accept=".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xlsx, .xls, .ods"
         v-show="false"
         @change="handleFile($event.target.files)"
    >

    <textarea class="chat__message-input hide-scrollbar" maxlength="250" placeholder="Введите текст..." ref="messageInput" @input="textAreaAdjust" @keypress.enter.exact.prevent="sendMessage" />

    <button class="chat__send-message button primary block" @click.prevent="sendMessage">
      <i class="mfcicon-send"></i>
    </button>
  </div>

</div>
</template>

<script>
import uploaderHelperMixin from '../../mixins/uploaderHelperMixin'
import ActionCable from 'actioncable'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import moment from 'moment'
import toastr from 'toastr'

export default {
    name: "Chat",
    mixins: [uploaderHelperMixin],
    props: {
        claimId: {
          type: Number,
          required: true
        },
        isAdditionalConsultation: {
          type: Boolean,
          required: true
        },
        canDoChatActions: {
          type: Boolean,
          default: false
        },
        messagesHistory: {
          type: Array,
        },
        userRole: {
          type: String,
          required: true
        },
        officialFullname: {
          type: String,
          required: true
        },
        citizenFullname: {
          type: String,
          required: true
        }
    },
    data() {
        return {
          text: "",
          messages: [],
          isUploading: false,
          validFileTypes: ".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xlsx, .xls, .ods"
        };
    },
    mounted() {
      this.messages = this.messagesHistory

      if (this.userRole == 'Expert') this.userRole = 'Citizen'
      
      this.consumer = ActionCable.createConsumer();
      this.cable = this.consumer.subscriptions.create({
        channel: "ChatChannel",
        claim_id: this.claimId
      }, {
        connected: () => {
          return console.log("Connected to chat")
        },
        received: (data) => {
          data.message.forEach(msg => {
            this.messages.unshift(msg)
          })
        }
      })
    },
    destroyed () {
      this.consumer.disconnect()
      this.consumer.subscriptions.remove(this.cable)
    },
    methods: {
      sendMessage(event) {
        if (this.$refs.messageInput.value.trim() === '') return

        try {
          this.$axios.post("/messages.json", {
            message: {
              text: this.$refs.messageInput.value.trim(),
              claim_id: this.claimId,
              kind: 'short'
            }
          })
          .then((response) => {
            return response.data
          })
          .then((data) => {
            if (data.success) {
              this.$refs.messageInput.value = ""
              this.textAreaAdjust()
            } else {
              toastr.error(data.message)
            }
          })
        }
        catch(error) {
          console.log(error)
        }
      },
      sendFile(file) {
        try {
          this.$axios.post("/messages.json", {
            message: {
              claim_id: this.claimId,
              document_id: file.id,
              kind: 'file'
            }
          }).then((response) => { console.log(response) })
        }
        catch(error) {
          console.log(error)
        }
      },
      async handleFile(files) {
        const file = first(files)
        this.$refs['file'].value = null
        if (!file || this.isUploading || !this.validFile(file)) return
  
        try {
          this.isUploading = true
          const formData = new FormData()

          formData.append('attachment[file]', file)
          formData.append('attachment[kind]', "message_document")
          formData.append('attachment[type]', "Attachment::Document")
            
          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData)

          data.resource.loaded = false
          this.sendFile(data.resource)
        } catch (error) {
          console.log(error)
        } finally {
          this.isUploading = false
        }
      },
      surnameWithInitials(fullname) {
        const names = fullname.split(' ')
        const surname = names[0]
        const initials = names.slice(1).map(name => name.charAt(0).toUpperCase() + '.')

        return `${surname} ${initials.join(' ')}`
      },
      textAreaAdjust() {
        this.$refs.messageInput.style.height = "1px";
        this.$refs.messageInput.style.height = (this.$refs.messageInput.scrollHeight > 96 ? 96 : this.$refs.messageInput.scrollHeight) + "px"
      },
      author(type) {
        switch (type) {
          case 'Admin':
            return 'Администратор системы'
          case 'Citizen':
            return this.surnameWithInitials(this.citizenFullname)
          case 'Official':
            return this.officialFullname || 'Сотрудник'
        }
      },
      validFile(file) {
        if (this.converter(file.size) > 50) {
          toastr.error('Файл большого размера (максимальный размер - 50 MB)')
          return false
        }
        if (!this.validFileTypes.includes(this.fileType(file.name.toLowerCase()))) {
          toastr.error('Файл имеет неверный формат. Разрешено загружать - jpg, jpeg, png, mp4, avi, mov, mkv, zip, rar, tar, gz, 7z, jpeg, png, tiff, doc, docx, pdf')
          return false
        }
        return true
      }
    },
    computed: {
      isChatEmpty() {
        return isEmpty(this.messages);
      }
    },
    filters: {
      formatDate(date) {
        return moment(date).format('DD MMMM YYYY [в] HH:mm')
      }
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/library/colours";

.claim__chat {
  margin-left: 48px;
  
  &:not(:first-child) {
    margin-top: 40px;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 679px;
  border-radius: 8px;
  background-color: $bg--silver-100;
  border: 1px solid $bg--silver-100;

  &__header {
    padding: 12px 20px;
    height: 56px;
  }

  &__title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  &__main {
    background-color: $bg--white-100;
    height: 100%;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 70px 24px;

    &-image {
      margin-bottom: 16px;

      background-image: url("../../assets/images/empty_chat_notification.svg");
      background-repeat: no-repeat;
      background-size: cover;
      height: 160px;
      width: 160px;
    }

    &-description {
      color: $text-tertiary--gray-500;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
	    
      user-select: none;
      -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    padding: 24px 16px;
    
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    padding: 12px;

    > * + * {
      margin-left: 7px;
    }
  }

  &__attach-file {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    height: 46px;
    width: 24px;
    cursor: pointer;
    color: $btn-default--orange-500;
    
    i {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 19px;
    }
  }

  &__message-input {
    background-color: $bg--white-100;
    resize: none;
    height: 46px;

    width: 100%;
    padding: 10px 16px 12px 16px;
    border-radius: 6px;
    border: 1px solid $bg--silver-200;
    outline: none;

    font-size: 16px;
    line-height: 24px;

    &[contenteditable]:empty:not(:focus):before {
      content: "Введите текст...";
      pointer-events: none;
      color: $text-tertiary--gray-500;
    }
    
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  &__send-message {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    height: 46px;
    width: 46px;
    border-radius: 6px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      
      font-size: 19px;
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 280px;
    margin-top: 12px;

    &:last-child {
      margin-top: 0;
    }

    &__author {
      margin-bottom: 4px;
      
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
      color: $text-tertiary--gray-500;
      font-weight: 500;
      font-size: 12px;
    }

    &__bubble {
      width: 100%;
      padding: 12px;

      border-radius: 8px;
      background-color: $state-expired-bg--tangerine-200;

      > * + * {
        margin-top: 12px;
      }

      &-text {
        width: 100%;
        // white-space: pre-line;
        word-break: break-word;

        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
      }

      &-documents {
        > * + * {
          margin-top: 12px;
        }
      }

      &-document {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 2px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        color: $link--blue-500;
        text-align: center;

        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 17px;
          width: 17px;
          margin-right: 6px;

          i {
            font-size: 17px;
          }
        }

        &-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &-send-date {
        font-weight: 500;
        font-size: 12px;
        color: $text-tertiary--gray-500;
      }
    }

    &--from-me {
      align-items: flex-end;
      align-self: flex-end;
      
      .message__author {
        max-width: 100%;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        align-items: end;
      }
      
      .message__bubble {
        background-color: $bg--silver-100;
      }
    }
  }
  .hide-scrollbar
  {
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
}
</style>