<template>
  <div class="new-schedule-datepicker-container">
    <p class="title">Дата консультации</p>
    <div class="schedule-datepicker" :class="{'orange-schedule-datepicker': orangeTheme}">
      <div class="custom-actions" row="sb cn">
        <p class="currentMonthAndYear">{{currentMonthAndYear}}</p>
        <div>
          <button @click="prev" type="button" class="button-previous">
            <i class="mfcicon-arrow-previous"></i>
          </button>
          <button @click="next" type="button" class="button-next">
            <i class="mfcicon-arrow-next"></i>
          </button>
        </div>
      </div>
      <input type="date" v-show="false" data-input="true">
      <input v-for="date in receptionDates" name="schedule[dates][]" :value="date" type="hidden">
    </div>
  </div>
</template>

<script>
  import flatpickr from "flatpickr"
  import {Russian} from "flatpickr/dist/l10n/ru.js"
  import 'flatpickr/dist/flatpickr.min.css'
  import map from 'lodash/map'
  import indexOf from 'lodash/indexOf'

  export default {
    name: 'NewScheduleDatepicker',
    props: {
      defaultDate: {
        type: String,
        default: ""
      },
      questionSubjectId: {
        default: null
      },
      departmentId: {
        default: null
      },
      departmentLimitDays: {
        default: 1,
      },
      orangeTheme: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        _flatpickr: {
          default: null
        },
        currentSchedule: {
          default: []
        },
        selectedSchedule: {
          default: {}
        },
        currentMonthAndYear: null,
        currentMonth: null,
        receptionDates: []
      }
    },
    methods: {
      async getShedule(month, departmentId, cb) {
        const currentDate = this.$moment(new Date()).format("YYYY-MM-DD");  // e. g. 2022-08-02
        this.$store.commit('SET_STATE', {path: ['todayInNewSchedule'], value: this.defaultDate == currentDate});
        this.$store.commit('SET_STATE', {path: ["schedule", "countSelectedDates"], value: 0});
        try {
          this.uploading = true;

          const {data} = await this.$axios.get(this.$routes.schedules_path(), {params: {m: month, filter: {department_id: departmentId}}});
          this.currentSchedule = data.resources;

          if (cb) cb(this.currentSchedule)

        } catch (e) {
          this.currentSchedule = [];
          this.cmpErrors = ['Непредвиденная ошибка'];
          if (cb) cb(this.currentSchedule)
        } finally {
          this.uploading = false
        }
      },
      formatMonth (m) {
        if (m < 1) m = 12;
        if (m == 13) m = 1;
        if (m.toString().length === 1) return(`0${m}`);
        return(m)
      },
      formatYear (m, y, mode) {
        if (parseInt(m) == 1 && mode == "next") {
          y = parseInt(y) + 1
        } else if (parseInt(m) == 12 && mode == "prev") {
          y = parseInt(y) - 1
        }
        return y
      },
      next () {
        let paramsMonthValue = this.formatMonth(this._flatpickr.currentMonth + 2);
        let paramsYearValue = this.formatYear(paramsMonthValue, this._flatpickr.currentYear, "next");

        this.getShedule(`${paramsMonthValue}-${paramsYearValue}`, this.departmentId, () => {
          let monthSelect = this._flatpickr.monthNav.querySelector(".flatpickr-monthDropdown-months");
          let yearInput = this._flatpickr.monthNav.querySelector(".numInput.cur-year");
          this.currentMonth = `${this.formatMonth(parseInt(monthSelect.value) + 2)}`;
          this._flatpickr.changeMonth(1);
          this.currentMonthAndYear = `${monthSelect.options[monthSelect.selectedIndex].text} ${yearInput.value}`
        })
      },
      prev () {
        let paramsMonthValue = this.formatMonth(this._flatpickr.currentMonth);
        let paramsYearValue = this.formatYear(this.formatMonth(this._flatpickr.currentMonth), this._flatpickr.currentYear, "prev");

        this.getShedule(`${paramsMonthValue}-${paramsYearValue}`, this.departmentId,() => {
          let monthSelect = this._flatpickr.monthNav.querySelector(".flatpickr-monthDropdown-months");
          let yearInput = this._flatpickr.monthNav.querySelector(".numInput.cur-year");
          this.currentMonth = `${this.formatMonth(parseInt(monthSelect.value))}`;
          this._flatpickr.changeMonth(-1);
          this.currentMonthAndYear = `${monthSelect.options[monthSelect.selectedIndex].text} ${yearInput.value}`
        })
      },
    },
    mounted() {
      const input = this.$el.querySelector('input') || this.$el;
      const moment = this.$moment;

      if (this.defaultDate) {
        this.receptionDates = [moment(this.defaultDate).format("DD.MM.YYYY")];
        this.currentMonth = moment(this.defaultDate).format("MM");
      } else {
        this.currentMonth = moment().format("MM");
      }

      const defaultOptions = {
        locale: Russian,
        allowInput: true,
        dateFormat: "Y-m-d",
        disableMobile: "true",
        mode: "multiple",
        disable: [
          (date) => {
            if (moment(date).format("MM") != this.currentMonth) return true;
            let notBefore = moment()
            notBefore.subtract(1, 'day')
            notBefore.add(this.departmentLimitDays, 'days')
            if (moment(date) < notBefore) return true;
          }
        ],
        inline: true,
        defaultDate: this.defaultDate,
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          if (this.currentSchedule && this.currentSchedule.length > 0) {
            let findValue = this.currentSchedule.find((v) => {
              return(moment(dayElem.dateObj).isSame(v.date))
            });
            if (findValue) {
              let dates = map(fp.selectedDates, (k,v) => { return moment(k).format("YYYY-MM-DD") });
              let idx = indexOf(dates, findValue.date);
              if (idx != -1) {
                fp.selectedDates.splice(idx, 1);
                fp.redraw();
              }
              dayElem.classList.add("flatpickr-disabled");
              dayElem.classList.add("available");
            }
          }
        },
        onReady: (dObj, dStr, fp, dayElem) => {
          this.getShedule(`${this.formatMonth(fp.currentMonth+1)}-${fp.currentYear}`, this.departmentId, (currentSchedule) => {
            fp.redraw();
            if (this.defaultDate) {
              if (currentSchedule && currentSchedule.length > 0) {
                let findValue = currentSchedule.find((v) => {
                  return(moment(this.defaultDate).isSame(v.date))
                });
                if (!findValue) {
                  findValue = {receptions: []}
                }
                this.$store.commit('SET_STATE', {path: ["schedule", "selected"], value: findValue});
                this.selectedSchedule = findValue
              }
            }
            let monthSelect = fp.monthNav.querySelector(".flatpickr-monthDropdown-months");
            let yearInput = fp.monthNav.querySelector(".numInput.cur-year");
            this.currentMonthAndYear = `${monthSelect.options[monthSelect.selectedIndex].text} ${yearInput.value}`;
            this.currentMonth = `${this.formatMonth(parseInt(monthSelect.value) + 1)}`
            fp.redraw();
          })
        },
        onChange: (selectedDates, dateStr, instance) => {
          this.receptionDates = map(selectedDates, (k,v) => {
            return moment(k).format("DD.MM.YYYY")
          })
          const current_date = moment(new Date()).format("DD.MM.YYYY");  // e. g. 09.07.2022
          this.$store.commit('SET_STATE', {path: ['todayInNewSchedule'], value: this.receptionDates.includes(current_date)});
          this.$store.commit('SET_STATE', {path: ["schedule", "countSelectedDates"], value: selectedDates.length});
        }
      };
      this._flatpickr = flatpickr(input, {...defaultOptions});
    },
    watch: {
      departmentId: function (newVal) {
        if (this._flatpickr) this._flatpickr.clear();
        this.getShedule(`${this.formatMonth(this._flatpickr.currentMonth + 1)}-${this._flatpickr.currentYear}`, newVal, () => {
          let monthSelect = this._flatpickr.monthNav.querySelector(".flatpickr-monthDropdown-months");
          let yearInput = this._flatpickr.monthNav.querySelector(".numInput.cur-year");
          this.currentMonthAndYear = `${monthSelect.options[monthSelect.selectedIndex].text} ${yearInput.value}`;
          this.currentMonth = `${this.formatMonth(parseInt(monthSelect.value) + 1)}`;
          this._flatpickr.redraw();
        })
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';
  .new-schedule-datepicker-container {
    height: 425px;
  }
  p.title {
    margin: 16px 0 10px 0;
  }
  .schedule-datepicker {
    position: relative;
    .flatpickr {
      &-calendar {
        width: 321px;
        box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 1px 0px rgba(0,0,0,0.08);
      }
      &-day {
        border-radius: 6px;
        margin: 2px 0;
        color: rgb(0, 0, 0);
        &:hover {
          background: rgb(245, 245, 245) !important;
          border: none;
          &.available {
            background-color: rgba(179, 214, 206, 1) !important;
          }
          &.today {
            color: rgb(0, 0, 0) !important;
          }
        }
        &.selected {
          background: rgb(245, 245, 245) !important;
          border: unset;
          color: rgb(0, 0, 0) !important;
          &:hover {
            background: rgb(225, 225, 225) !important;
            border: unset;
          }
          &:focus {
            background: rgb(245, 245, 245) !important;
          }
          &.available {
            background-color: rgb(237, 83, 56) !important;
            color: rgba(255, 255, 255, 1) !important;
          }
        }
        &.today {
          border: 1px solid !important;
          &:focus {
            background: rgb(245, 245, 245) !important;
            color: rgb(0, 0, 0) !important;
          }
        }
      }
      &-disabled {
        border-radius: 6px;
        color: rgb(0, 0, 0);
        color: rgba(57,57,57,0.1) !important;
        cursor: default !important;
        &:hover {
          background: none !important;
          background-color: none !important;
          border: 1px solid white !important;
          &.today {
            border: 1px solid #ebeae6 !important;
            color: rgba(57,57,57,0.1) !important;
          }
        }
      }
      &-innerContainer {
        padding: 7px;
      }
      &-current-month {
        left: 5px;
        top: 12px;
        width: auto;
      }
      &-month {
        height: 60px;
      }
    }
    .event {
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 150px;
      bottom: 3px;
      left: calc(50% - 1.5px);
      content: " ";
      display: block;
      background: #3d8eb9;
    }

    .event.busy {
      background: #f64747;
    }

    span.flatpickr-next-month, span.flatpickr-prev-month, .flatpickr-current-month {
      display: none;
    }

    .custom-actions {
      z-index: 10;
      position: absolute;
      top: 14px;
      right: 0px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 0 14px 0 19px;
    }

    p.currentMonthAndYear {
      margin: 0;
    }

    button {
      &.button-previous, &.button-next {
        background: rgb(245, 245, 245);
        border-radius: 6px;
        height: 40px;
        width: 40px;
        padding: 0;
        i {
          font-size: 12px;
          color: rgb(135, 135, 135);
        }
      }
      &:hover {
        background-color: rgba(223, 222, 218, 1);
      }
      &:active {
        background-color: rgba(195, 194, 190, 1);
      }
    }

  }
  .orange-schedule-datepicker .flatpickr-day.selected {
    background-color: rgb(237, 83, 56) !important;
    border: unset;
    color: rgba(255, 255, 255, 1) !important;
    &:hover {
      background: rgb(237, 83, 56)!important;
      border: unset;
    }
    &:focus {
      background: rgb(237, 83, 56)!important;
      border: unset;
    }
    &.available {
      background-color: rgb(237, 83, 56) !important;
      color: rgba(255, 255, 255, 1) !important;
    }
  }
</style>
