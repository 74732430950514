<script>
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

export default {
  props: ["currentQuestionSubject", "departmentsList", "currentDepartment", "path"],
  data() {
    return {
      current: {}
    }
  },
  watch: {
    currentQuestionSubject(n) {
      if (!isEmpty(n) && n.id !== 'all') {
        let value = find(this.departmentsList, (d) => {
          return d.id === n.department_id;
        })
        let path = this.path
        this.$parent.currentDepartment = value;
        this.$store.commit('SET_STATE', {path: path, value: value})
        this.$store.commit('SET_ERRORS', {path: path, value: []})
      }
    }
  }
}
</script>
