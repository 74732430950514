<script>
import MfcSelect from '../form/MfcSelect'
import categoryQuestions from './CategoryQuestions'
import categoryPrograms from './CategoryPrograms'

export default {
  components: {
    questions: categoryQuestions,
    programs: categoryPrograms,
    mfcselect: MfcSelect
  },
  data() {
    return {
      currentQuestionSubjects: null,
      currentProgram: null
    }
  },
  methods: {
    setQuestionSubjects(questionSubjects) {
      this.currentQuestionSubjects = questionSubjects;
    },
    setProgram(program) {
      this.currentProgram = program;
    }
  }
}
</script>
